<template>
  <products-manage :typePage="typePage"></products-manage>
</template>

<script>
import ProductsManage from "@/views/modules/collection/business/products/productsManage.vue";

export default {
    name: "productsExamine",
    components: {ProductsManage},
    data(){
        return{
            typePage:'productsExamine',
        }
    },
}
</script>

<style scoped>

</style>